.icon_default {
  width: 28px;
  height: 28px;
  margin-left: 8px;
  margin-right: 8px;
}

@media screen and (max-width: 960px) {
  .icon_default {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    margin-right: 4px;
  }
}
