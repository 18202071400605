.text_secondary {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: lighter;
  font-family: Consolas, Monaco, monospace;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {}
@media screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {}
@media screen and (max-width: 1120px) {}
@media screen and (max-width: 960px) {}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {
  .text_secondary {
    font-size: 15px;
  }
}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {
  .text_secondary {
    font-size: 14px;
  }
}
@media screen and (max-width: 400px) {}
@media screen and (max-width: 400px) {}
