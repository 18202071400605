.typing_text {
  font: bold 200% Consolas, Monaco, monospace;
  font-size: 44px;
  font-weight: 100;
  text-decoration: underline;
  border-right: 0.1em solid;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 2.5s steps(31, end),
  cursor-blink 0.3s step-end infinite alternate;
}

@keyframes typing {
  from {
      width: 0;
  }
}

@keyframes cursor-blink {
  50% {
      border-color: transparent;
  }
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {}
@media screen and (max-width: 1440px) {
  .typing_text {
    font-size: 36px;
  }
}
@media screen and (max-width: 1280px) {
  .typing_text {
    font-size: 32px;
  }
}
@media screen and (max-width: 1120px) {
  .typing_text {
    font-size: 30px;
  }
}
@media screen and (max-width: 960px) {
  .typing_text {
    font-size: 26px;
  }
}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {
  .typing_text {
    font-size: 22px;
  }
}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {
  .typing_text {
    font-size: 20px;
  }
}
@media screen and (max-width: 560px) {
  .typing_text {
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  .typing_text {
    font-size: 17px;
  }
}
@media screen and (max-width: 400px) {
  .typing_text {
    font-size: 16px;
  }
}
@media screen and (max-width: 360px) {
  .typing_text {
    font-size: 14px;
  }
}
