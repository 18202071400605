.curriculum_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.curriculum_title {
  margin-top: 64px;
  margin-bottom: 64px;
}

.curriculum_sub_container{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.curriculum_timeline_container {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
}

.curriculum_professional {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.curriculum_professional_cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.curriculum_professional_title {
  margin-top: 16px;
  margin-bottom: 16px;
}

.curriculum_education {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.curriculum_education_title {
  margin-top: 16px;
  margin-bottom: 16px;
}

.curriculum_education_cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.curriculum_skills {
  width: 36%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 1px solid #676767;
  padding-left: 32px;
  padding-bottom: 32px;
}

.curriculum_skills_title {
  margin-top: 16px;
  margin-bottom: 16px;
}

.curriculum_skills_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.curriculum_skills_container_title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.curriculum_skills_sub_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {}

@media screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {
  .curriculum_sub_container {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .curriculum_timeline_container {
    width: 90%;
  }

  .curriculum_skills {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-left: 0px;
    padding-left: 0px;
    border-top: 1px solid #676767;
    padding-top: 16px;
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 1120px) {}
@media screen and (max-width: 960px) {}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {
  .curriculum_title {
    margin-bottom: 32px;
  }

}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {}
@media screen and (max-width: 400px) {}
