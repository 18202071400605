.portfolio_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.portfolio_container_title {
  margin-top: 64px;
  margin-bottom: 64px;
  width: 100%;
}

.portfolio_sub_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
}

.portfolio_menu {
  margin-top: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.portfolio_menu_items {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 60%;
}

.portfolio_card_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 960px) {}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {
  .portfolio_menu_items {
    width: 65%;
  }

  .portfolio_menu {
    margin-top: 32px;
  }

  .portfolio_container_title {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {
  .portfolio_menu {
    margin-top: 16px;
  }

  .portfolio_container_title {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 400px) {}
