.what_i_do_card {
  width: 632px;
  min-height: 144px;
  margin-bottom: 64px;
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  transition: transform 0.3s;
  padding: 8px;
  border-radius: 8px;
}

.what_i_do_card_icon_container {
  height: 72px;
  width: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #454545;
  border-radius: 8px;
}

.what_i_do_card_container_title {
  margin-bottom: 8px;
}

.what_i_do_card_container_text {
  width: 100%;
}

.what_i_do_card_icon {
  height: 48px;
  width: 48px;
}

.what_i_do_card_container {
  margin-left: 16px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  text-align: left;
}

.what_i_do_card:hover {
  transform: translateY(-10px);
}

@media screen and (max-width: 1760px) {
  .what_i_do_card {
    width: 600px;
    margin-left: 36px;
    margin-right: 36px;
  }
}

@media screen and (max-width: 1680px) {
  .what_i_do_card {
    width: 524px;
    margin-left: 36px;
    margin-right: 36px;
  }
}

@media screen and (max-width: 1600px) {
  .what_i_do_card {
    width: 488px;
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media screen and (max-width: 1440px) {
  .what_i_do_card {
    width: 452px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 64px;
  }

}

@media screen and (max-width: 1280px) {
  .what_i_do_card {
    width: 400px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media screen and (max-width: 1120px) {
  .what_i_do_card {
    width: 416px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 960px) {
  .what_i_do_card {
    width: 520px;
  }

  .what_i_do_card_container {
    margin-left: 16px;
  }
}

@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {
  .what_i_do_card {
    width: 464px;
  }
}

@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {
  .what_i_do_card {
    width: 436px;
  }
}

@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {
  .what_i_do_card {
    width: 400px;
  }
}

@media screen and (max-width: 400px) {}
