.title_curriculum {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-family: Consolas, Monaco, monospace;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {}
@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1280px) {

}

@media screen and (max-width: 1120px) {}
@media screen and (max-width: 960px) {

}

@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}

@media screen and (max-width: 560px) {
  .title_curriculum {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .title_curriculum {
    font-size: 17px;
  }
}

@media screen and (max-width: 400px) {
  .title_curriculum {
    font-size: 16px;
  }
}
