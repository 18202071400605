.portfolio_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 16px;
  height: auto;
  width: 400px;
  border-end-end-radius: 16px;
  border-end-start-radius: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  cursor: pointer;
  transition: 0.2s;
}

.portfolio_card_skills {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.portfolio_card_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.portfolio_card_name {
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  width: auto;
}

.portfolio_card_description {
  margin-left: 8px;
  margin-bottom: 8px;
  width: auto;
}

.portfolio_card_image {
  max-width: 400px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.portfolio_card_see_details {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: fixed;
  height: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.portfolio_card:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 640px) {
  .portfolio_card_image {
    max-width: 380px;
  }

  .portfolio_card {
    width: 380px;
  }
}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {
  .portfolio_card_image {
    max-width: 340px;
  }

  .portfolio_card {
    width: 340px;
  }
}
@media screen and (max-width: 400px) {}
@media screen and (max-width: 360px) {
  .portfolio_card_image {
    max-width: 300px;
  }

  .portfolio_card {
    width: 300px;
  }
}
