.change_lang_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 136px;
  cursor: pointer;
}

.change_lang_options_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 94px;
  margin-left: -17px;
  border-radius: 8px;
}

.change_lang_option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid;
}

.change_lang_option_text {
  padding: 0;
  margin: 0;
  font-family: Consolas, Monaco, monospace;
  font-size: 16px;
}

.change_lang_option_icon {
  height: 28px;
  width: 28px;
}

.change_lang_selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

@media screen and (max-width: 960px) {
  .change_lang_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    margin-left: 16px;
    margin-right: 8px;
  }

  .change_lang_options_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 48px;
    margin-top: 188px;
    margin-left: -16px;
  }

  .change_lang_option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  .change_lang_selected {
    width: 40px;
    margin-right: 8px;
    justify-content: center;
  }
}
