.skill_card {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  transition: 0.2s;
}

.skill_card:hover {
  transform: scale(1.08);
}
