.details_card_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin-bottom: 144px;
}

.details_card_header {
  border-bottom: 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.details_card_subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  padding: 16px;
  border-end-end-radius: 16px;
  border-end-start-radius: 16px;
}

.details_card_image {
  height: 320px;
  border-radius: 16px;
}

.details_card_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
}

.details_card_info_description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.details_card_info_skills {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.details_card_info_sub_container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.details_card_link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.details_card_title {
  width: 90%;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {
  .details_card_image {
    height: 280px;
  }
}
@media screen and (max-width: 1440px) {
  .details_card_image {
    height: 240px;
  }
}
@media screen and (max-width: 1280px) {
  .details_card_image {
    height: 220px;
  }
}
@media screen and (max-width: 1120px) {
  .details_card_image {
    height: 180px;
  }
}
@media screen and (max-width: 960px) {
  .details_card_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 96%;
  }

  .details_card_image {
    height: auto;
  }

  .details_card_subcontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    padding: 16px;
    border-end-end-radius: 16px;
    border-end-start-radius: 16px;
  }

  .details_card_info {
    width: 100%;
    padding-left: 0px;
    margin-top: 32px;
  }
}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {
  .details_card_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 90%;
  }
}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {}
@media screen and (max-width: 400px) {}
