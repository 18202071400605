.header {
  display: flex;
  position: fixed;
  width: 260px;
  min-height: 640px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header_profile_pic_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header_profile_pic {
  margin-top: 32px;
  width: 160px;
  border: 2px solid;
  border-radius: 100%;
}

.header_menu {
  width: 100%;
  margin-top: -160px;
}

.header_menu_ul {
  padding: 0;
}

.header_links_container {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.header_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.header_links_container_main {
  width: 16%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.icon_menu {
  height: 32px;
  width: 32px;
}

@media screen and (max-width: 960px) {
  .header {
    display: flex;
    position: fixed;
    height: 64px;
    min-height: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .header_menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 240px;
    margin-top: 10px;
    margin-left: -200px;
    border-radius: 8px;
  }

  .header_menu_line {
    width: 224px;
    border-bottom: 1px solid;
  }
}

@media screen and (max-width: 864px) {
  .header_links_container_main {
    width: 160px;
  }
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 704px) {
  .icon_menu {
    height: 28px;
    width: 28px;
  }

  .header_menu {
    width: 220px;
  }

  .header_menu_line {
    width: 204px;
    border-bottom: 1px solid;
  }

  .header {
    height: 56px;
  }
}

@media screen and (max-width: 640px) {
  .header_links_container_main {
    width: 140px;
  }
}

@media screen and (max-width: 560px) {
  .icon_menu {
    height: 24px;
    width: 24px;
  }
}

@media screen and (max-width: 480px) {
  .header_links_container_main {
    width: 120px;
  }

  .header {
    height: 48px;
  }
}
@media screen and (max-width: 400px) {

}
@media screen and (max-width: 360px) {

}
