.title_default {
  font-size: 32px;
  font-family: Consolas, Monaco, monospace;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {}
@media screen and (max-width: 1440px) {
  .title_default {
    font-size: 28px;
  }
}
@media screen and (max-width: 1280px) {}
@media screen and (max-width: 1120px) {}
@media screen and (max-width: 960px) {
  .title_default {
    font-size: 24px;
  }
}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {
  .title_default {
    font-size: 22px;
  }
}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {
  .title_default {
    font-size: 20px;
  }
}
@media screen and (max-width: 400px) {}
