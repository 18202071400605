.timeline_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  width: 100%;
}

.timeline_card_column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.timeline_card_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.timeline_card_where {
  width: 160px;
  height: 120px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.timeline_card_when {
  margin-top: 16px;
  margin-bottom: 4px;
  width: 160px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  border-bottom: 1px solid;
  padding-bottom: 4px;
}

.timeline_card_when_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.timeline_card_name {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.timeline_card_description {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
}

.timeline_card_line_container {
  width: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.timeline_card_line_top {
  width: 2px;
  height: 16%;
}

.timeline_card_line_bottom {
  width: 2px;
  height: 84%;
}

.timeline_card_indicator {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_card_indicator_core {
  height: 6px;
  width: 6px;
  border-radius: 100%;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {}
@media screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {}
@media screen and (max-width: 1120px) {}
@media screen and (max-width: 960px) {}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {
  .timeline_card_when {
    width: 120px;
  }

  .timeline_card_where {
    width: 120px;
  }
}
@media screen and (max-width: 704px) {
  .timeline_card_when {
    width: 112px;
  }

  .timeline_card_where {
    width: 112px;
  }
}
@media screen and (max-width: 640px) {
  .timeline_card_when {
    width: 104px;
  }

  .timeline_card_where {
    width: 104px;
  }
}
@media screen and (max-width: 560px) {
  .timeline_card_when {
    width: 88px;
  }

  .timeline_card_where {
    width: 88px;
  }
}
@media screen and (max-width: 480px) {
  .timeline_card_when {
    width: 80px;
  }

  .timeline_card_where {
    width: 80px;
  }
}
@media screen and (max-width: 400px) {}
@media screen and (max-width: 360px) {
  .timeline_card_when {
    width: 72px;
  }

  .timeline_card_where {
    width: 72px;
  }
}
