.text_default {
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: lighter;
  font-family: Consolas, Monaco, monospace;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {}
@media screen and (max-width: 1440px) {
  .text_default {
    font-size: 20px;
  }
}

@media screen and (max-width: 1280px) {
  .text_default {
    font-size: 19.5px;
  }
}

@media screen and (max-width: 1120px) {}
@media screen and (max-width: 960px) {
  .text_default {
    font-size: 19px;
  }
}

@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}

@media screen and (max-width: 560px) {
  .text_default {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .text_default {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {}
@media screen and (max-width: 360px) {
  .text_default {
    font-size: 14px;
  }
}
