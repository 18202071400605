
.contact_sub_container_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  height: 56px;
  margin-bottom: 32px;
  width: 30%;
  cursor: pointer;
  transition: 0.1s;
}

.contact_card_link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.contact_icon {
  height: 36px;
  width: 36px;
}

.contact_sub_container_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact_sub_container_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact_sub_container_row:hover {
  transform: scale(1.04);
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {
  .contact_sub_container_row {
    width: 35%;
  }
}
@media screen and (max-width: 1600px) {
  .contact_sub_container_row {
    width: 40%;
  }
}
@media screen and (max-width: 1440px) {
  .contact_sub_container_row {
    width: 45%;
  }
}
@media screen and (max-width: 1280px) {}
@media screen and (max-width: 1120px) {}
@media screen and (max-width: 960px) {
  .contact_sub_container_row {
    width: 80%;
  }
}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {}
@media screen and (max-width: 400px) {}
