.what_i_do_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.what_i_do_title {
  margin-top: 32px;
  margin-bottom: 32px;
}

.what_i_do_sub_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

