.switch_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.input_switch[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
  margin: -2px;
  padding: 0px;

}

.label_switch {
	cursor: pointer;
	width: 48px;
	height: 24px;
	background: #878787;
	border-radius: 100px;
	position: relative;
  margin: 0px;
  padding: 0px;
	display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.switch_icon_sun {
  height: 20px;
  width: 20px;
  margin-right: 2px;
  color: #fcff7a;
}

.switch_icon_moon {
  height: 20px;
  width: 20px;
  color: #e5e5e5;
}

.label_switch:after {
	content: '';
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	background: #404040;
	border-radius: 100%;
	transition: 0.1s;
  margin: 0px;
  padding: 0px;
}

.input_switch:checked + .label_switch {
	background: #878787;
}

.input_switch:checked + .label_switch:after {
	left: calc(100% - 1px);
	transform: translateX(-100%);
}

.label_switch:active:after {
	width: 24px;
}

@media screen and (max-width: 768px) {}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {
  .label_switch {
    cursor: pointer;
    width: 32px;
    height: 16px;
    background: #878787;
    border-radius: 100px;
    position: relative;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .switch_icon_sun {
    height: 14px;
    width: 14px;
    margin-right: 1px;
  }

  .switch_icon_moon {
    height: 14px;
    width: 14px;
  }

  .label_switch:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 14px;
    height: 14px;
    background: #404040;
    border-radius: 100%;
    transition: 0.1s;
    margin: 0px;
    padding: 0px;
  }

  .input_switch:checked + .label_switch {
    background: #878787;
  }

  .input_switch:checked + .label_switch:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  .label_switch:active:after {
    width: 20px;
  }
}
@media screen and (max-width: 480px) {}
@media screen and (max-width: 400px) {}
@media screen and (max-width: 360px) {}
