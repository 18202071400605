.about_me_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.about_me_title {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 32px;
}

.about_me_sub_container {
  width: 100%;
  height: 560px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.about_me_sub_container_text {
  width: 50%;
  height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 16px;
}

.about_me_sub_container_info {
  width: 40%;
  height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 32px;
}

.about_me_info_row_container {
  width: 100%;
}

.about_me_info_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about_me_info_row_line {
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid;
}

.about_me_main_line {
  margin-top: 8px;
  margin-bottom: 8px;
  height: 80%;
  border-left: 1px solid;
}

.about_me_info_download {
  height: 48px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 16px;
  padding-right: 8px;
  transition: 0.2s;
}

.about_me_info_download_a {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  text-decoration: none;
}

.about_me_info_download:hover {
  transform: scale(1.04);
  cursor: pointer;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {
  .about_me_sub_container_text {
    width: 48%;
  }

  .about_me_sub_container_info {
    width: 40%;
  }
}

@media screen and (max-width: 1600px) {
  .about_me_sub_container_info {
    width: 42%;
  }
}

@media screen and (max-width: 1440px) {
  .about_me_sub_container_info {
    width: 44%;
  }
}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 1120px) {
  .about_me_info_row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 960px) {
  .about_me_sub_container {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .about_me_sub_container_text {
    width: 90%;
    height: auto;
    margin-left: 0px;
  }

  .about_me_sub_container_info {
    margin-top: 32px;
    width: 90%;
    min-height: 200px;
    height: auto;
  }

  .about_me_info_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .about_me_info_download {
    height: 40px;
  }

  .about_me_main_line {
    height: 1px;
    width: 90%;
    border-left: 0px;
    border-bottom: 1px solid #676767;
  }
}

@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {}
@media screen and (max-width: 400px) {}
@media screen and (max-width: 960px) {}
