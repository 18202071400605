.title_page {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 40px;
  font-family: Consolas, Monaco, monospace;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {}
@media screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {}
@media screen and (max-width: 1120px) {}
@media screen and (max-width: 960px) {
  .title_page {
    font-size: 32px;
  }
}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {}
@media screen and (max-width: 704px) {
  .title_page {
    font-size: 28px;
  }
}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {
  .title_page {
    font-size: 24px;
  }
}
@media screen and (max-width: 400px) {}
@media screen and (max-width: 360px) {

}
