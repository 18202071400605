.text_list {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  font-family: Consolas, Monaco, monospace;
}

.text_list_li {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  height: 32px;
  cursor: pointer;
}

@media screen and (max-width: 1760px) {}
@media screen and (max-width: 1680px) {}
@media screen and (max-width: 1600px) {}
@media screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {}
@media screen and (max-width: 1120px) {}
@media screen and (max-width: 960px) {}
@media screen and (max-width: 864px) {}
@media screen and (max-width: 768px) {}
@media screen and (max-width: 704px) {}
@media screen and (max-width: 640px) {}
@media screen and (max-width: 560px) {}
@media screen and (max-width: 480px) {}
@media screen and (max-width: 400px) {}
